import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Core/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div
      className="app"
      style={{ height: "100vh", backgroundColor: "#F4F4FC" }}
    >
      <section style={{ paddingTop: "160px" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-5 text-center">
              <h2>Oops! Page not found..</h2>
              <p className="text-muted mt-3">
                The page or resource you requested may have been moved, deleted,
                or you entered the wrong URL.
              </p>
              <Link to="/#" className="btn btn-primary">
                Back to Home
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default NotFoundPage
